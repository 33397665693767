import {
  DYNAMIC_DOMAIN_ROOT,
  DYNAMIC_DOMAIN_MAP,
} from 'constants/dynamic-domain';

const DEFAULT_SITE_DOMAIN =
  process.env.NEXT_PUBLIC_SITE_DOMAIN || 'https://www.lume.com';

export function getSiteDomain(ctx) {
  const { resolvedUrl } = ctx ?? {};
  if (resolvedUrl && resolvedUrl.startsWith(DYNAMIC_DOMAIN_ROOT)) {
    const dynamicDomainRoutePath = resolvedUrl.replace(DYNAMIC_DOMAIN_ROOT, '');
    const { SITE_DOMAIN } =
      Object.values(DYNAMIC_DOMAIN_MAP).find(
        (x) => x.ROUTE_PATH === dynamicDomainRoutePath
      ) ?? {};

    if (SITE_DOMAIN) return SITE_DOMAIN;
  }

  return DEFAULT_SITE_DOMAIN;
}
