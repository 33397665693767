/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import logger from 'config/logger';
import { RequestInstance } from 'request';
import setIdleInterval from 'utils/set-idle-interval';

async function getSegmentedPageData({ cmsPageId, cmsUrlParam, location }) {
  try {
    const landingPageData = await RequestInstance.post(
      'api/content/segmented-landing-page',
      {
        json: {
          cmsPageId,
          cmsUrlParam,
          location,
        },
      }
    );
    return landingPageData ?? {};
  } catch (err) {
    logger.error(
      err,
      `Error retrieving segmented layout page components for url:${cmsUrlParam} location:${location}`
    );
    return [];
  }
}
async function getProductMap({ location, menu }) {
  try {
    if (!location || !menu) {
      return {};
    }
    const {
      result: { products: newProducts },
    } = await RequestInstance.post('api/menu/get-cached-menu', {
      json: {
        filters: 'Offers^term^NEW',
        menu_type: menu.toUpperCase(),
        store_name: location,
        sort: 'most_popular',
        use_composite_caches: false,
      },
    });

    return { NEW: newProducts };
  } catch (err) {
    logger.error(err, `Error retrieving product map for location:${location}`);
    return {};
  }
}

async function getPageData({ cmsPageId, cmsUrlParam, location, menu }) {
  const [segmentedPageCall, productMapCall] = await Promise.allSettled([
    getSegmentedPageData({ cmsPageId, cmsUrlParam, location }),
    getProductMap({ location, menu }),
  ]);

  let pageData = {};
  let productMap = {};

  if (segmentedPageCall.status === 'fulfilled')
    pageData = segmentedPageCall.value;

  if (productMapCall.status === 'fulfilled') productMap = productMapCall.value;

  return { pageData, productMap };
}

function useGetSegmentedLandingPage({
  data,
  propProductMap,
  location,
  menu,
  pageId,
}) {
  const selectedMenu = useSelector((state) => state.selectedMenu);
  const storeSelection = useSelector((state) => state.storeSelection);

  const [pageData, setPageData] = useState(data);
  const [productMap, setProductMap] = useState(propProductMap);

  const storedLocation = useRef(location);
  const storedMenu = useRef(menu);

  useEffect(() => {
    const get = () => {
      getPageData({
        cmsPageId: pageId,
        cmsUrlParam: pageData.url,
        location: storeSelection,
        menu: selectedMenu,
      }).then((data) => {
        const { pageData: pd, productMap: pm } = data;
        setPageData(pd);
        setProductMap(pm);
      });
    };

    if (storeSelection && storeSelection !== storedLocation.current) {
      storedLocation.current = storeSelection;
      get();
    }

    if (selectedMenu && selectedMenu !== storedMenu.current) {
      storedMenu.current = selectedMenu;
      get();
    }
    // refresh every hour
    const cleanup = setIdleInterval(() => {
      get();
    }, 3_600_000);

    return () => {
      cleanup();
    };
  }, [storeSelection]);

  useEffect(() => {
    setPageData(data);
  }, [data]);

  useEffect(() => {
    setProductMap(propProductMap);
  }, [propProductMap]);

  return { pageData, productMap };
}
export default useGetSegmentedLandingPage;
