import Head from 'next/head';
import { getSiteDomain } from 'utils/get-site-domain';

export default function PageMeta({ canonical, description, keywords, title }) {
  return (
    <Head>
      {title ? (
        <>
          <title>{title}</title>
          <meta name="twitter:title" content={title} />
          <meta property="og:title" content={title} />
        </>
      ) : null}
      {description ? (
        <>
          <meta name="description" content={description} />
          <meta name="twitter:description" content={description} />
          <meta property="og:description" content={description} />
        </>
      ) : null}
      {keywords ? <meta name="keywords" content={keywords} /> : null}
      {canonical ? (
        <>
          <link rel="canonical" href={canonical} />
          <meta property="og:url" content={canonical} />
          <meta name="twitter:url" content={canonical} />
        </>
      ) : null}
      <meta
        property="og:image"
        content={`${getSiteDomain()}/lume-og-image-1024x512.png`}
      />
    </Head>
  );
}
